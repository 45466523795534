<template>
  <v-container id="productRequest" fluid tag="section">
    <v-snackbar v-model="isAction" :timeout="4000" top>
      <span>{{ actionMessage }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="isAction = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-container class="py-2">
      <v-row>
      <v-col cols="4" md="4" >
        <v-select
          :items="storeNames"
          label="Select Store To Generate Reports"
          class="purple-input"
          v-model="store"
          @change="getSelectedStore"
        />
      </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" md="4">
          <v-select
            :items="reports"
            label="Select Generate Reports"
            class="purple-input"
            v-model="selectReport"
            @change="getRelatedData"
          />
        </v-col>
          <!-- <v-row> -->
            <!-- <v-col cols="4" md="4" class="text-right">
              <v-select
                :items="payoutReports"
                label="Types of Payout Reports"
                class="purple-input"
                v-model="selectPayoutReports"
                @change="getSalesData"
              />
            </v-col> -->
            <v-col v-if="selectReport == 'Payout Report'" cols="4" md="4" class="text-left">
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="payoutDateRange"
                      label="Select Date Range"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    @change="getPayoutData"
                    v-model="payoutDateRange"
                    range
                  ></v-date-picker>
                </v-menu>
            </v-col>
          <!-- </v-row> -->
        <v-col
          cols="4"
          md="4"
          class="text-right"
          v-if="selectReport == 'Damage Product'"
        >
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="damageDateRange"
                label="Date Range"
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              @change="getDate"
              v-model="damageDateRange"
              range
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="4"
          md="4"
          class="text-left"
          v-if="selectReport == 'Van Inventory'"
        >
          <v-select
            @change="getVanDetails"
            :items="vans"
            label="Select Van"
            item-text="vehicle_number"
            item-value="_id"
            v-model="vanId"
          ></v-select>
        </v-col>
        <v-col cols="4" md="4" class="text-right" v-if="selectReport">
          <v-btn
            v-if="
              selectReport == 'Products Inventory' ||
                selectReport == 'Van Inventory'
            "
            type="button"
            color="primary"
            class="mr-0"
            @click="downloadExcel"
            :loading="loadingExcel"
          >
            Download
          </v-btn>

          <v-btn
            v-if="
              selectReport != 'Products Inventory' &&
                selectReport != 'Van Inventory'
            "
            type="button"
            color="primary"
            class="mr-0"
            @click="downloadExcel"
            :loading="loadingExcel"
          >
            Download
          </v-btn>
        </v-col>
      </v-row>
      <div v-if="selectReport == 'Sales Report'">
        <v-row>
          <v-col cols="4" md="4" class="text-left">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="salesDateRange"
                  label="Select Date Range"
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @change="getSalesData"
                v-model="salesDateRange"
                range
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4" md="4" class="text-right">
            <v-select
              :items="salesReports"
              label="Types of Sales Reports"
              class="purple-input"
              v-model="selectSalesReports"
              @change="getSalesData"
            />
          </v-col>
          <v-col cols="4" md="4" class="text-right">
            <v-btn
              type="button"
              color="primary"
              class="mr-0"
              @click="clearFilter"
            >
              Clear Filter
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" md="3">
            <v-select
              :items="allCategories"
              label="Select Categories"
              item-text="name"
              item-value="_id"
              v-model="categoryId"
              @change="getSubcategory"
              :disabled="salesDateRange.length == 0 || selectSalesReports == ''"
            >
            </v-select>
          </v-col>
          <v-col cols="3" md="3">
            <v-select
              :items="allSubCategories"
              label="Select SubCategories"
              item-text="name"
              item-value="_id"
              v-model="subCategoryId"
              @change="getProduct"
              :disabled="allSubCategories.length == 0"
            ></v-select>
          </v-col>
          <v-col cols="3" md="3">
            <v-select
              :items="allProducts"
              label="Select Products"
              item-text="name"
              item-value="_id"
              v-model="productId"
              @change="getProductsVariants"
              :disabled="allProducts.length == 0"
            ></v-select>
          </v-col>
          <v-col cols="3" md="3">
            <v-select
              :items="productsVariants"
              label="Select Product Variants"
              item-text="variant_quantity_per_pack"
              item-value="_id"
              v-model="variantId"
              @change="getVariantId"
              :disabled="productsVariants.length == 0"
            >
              <template slot="selection" slot-scope="data">
                {{
                  data.item.variant_quantity_per_pack +
                    "x " +
                    data.item.variant_volume_value +
                    " " +
                    data.item.variant_volume_type +
                    " " +
                    data.item.variant_type
                }}
              </template>
              <template slot="item" slot-scope="data">
                {{
                  data.item.variant_quantity_per_pack +
                    "x " +
                    data.item.variant_volume_value +
                    " " +
                    data.item.variant_volume_type +
                    " " +
                    data.item.variant_type
                }}
              </template>
            </v-select>
          </v-col>
        </v-row>
      </div>
      <div v-if="selectReport == 'Orders Report'">
        <v-row>
          <v-col cols="4" md="4" class="text-left">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="orderDateRange"
                  label="Select Date Range"
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @change="getOrdersData"
                v-model="orderDateRange"
                range
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4" md="4" class="text-right">
            <v-select
              :items="ordersReports"
              label="Types of Orders Reports"
              class="purple-input"
              v-model="selectOrdersReports"
              @change="getOrdersData"
            />
          </v-col>
          <v-col cols="4" md="4" class="text-right">
            <v-btn
              type="button"
              color="primary"
              class="mr-0"
              @click="clearFilterOrders"
            >
              Clear Filter
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <v-card v-if="selectReport == 'Products Inventory'">
        <v-card-title>Products Inventory</v-card-title>
        <v-container>
          <!-- <v-data-table
            :headers="columnsProductsInventory"
            :items="productsInventory"
            @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            item-key="_id"
            show-expand
            class="elevation-1"
            hide-default-footer
            :loading="loadingProductInventory"
            loading-text="Loading... Please wait"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col>
                    <v-text-field
                      @keyup="globalSearch"
                      v-model="search"
                      label="Search"
                      class="mx-4 mt-4"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:expanded-item="{ item }">
              <td colspan="8">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Quantity Per Pack</th>
                        <th>Type</th>
                        <th>Volume Type</th>
                        <th>Volume Value</th>
                        <th>Actual Price</th>
                        <th>Retail Price</th>
                        <th>Quantity</th>
                        <th>Image</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="variant in item.product_variants"
                        :key="variant._id"
                      >
                        <td>{{ variant.variant_quantity_per_pack }}</td>
                        <td>{{ variant.variant_type }}</td>
                        <td>{{ variant.variant_volume_type }}</td>
                        <td>{{ variant.variant_volume_value }}</td>
                        <td>{{ variant.variant_actual_price }}</td>
                        <td>{{ variant.variant_retail_price }}</td>
                        <td>{{ variant.variant_quantity }}</td>
                        <td>
                          <v-img
                            height="100"
                            width="50"
                            aspect-ratio="1"
                            :src="imageUrl + variant.variant_image"
                            :lazy-src="imageUrl + variant.variant_image"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </template>
          </v-data-table>
          <div class="custom_pagination_design">
            <span>
              Showing <b>{{ startRecord }}</b> to <b>{{ endRecord }}</b> of
              <b>{{ totalRecord }}</b> Records
            </span>
            <v-pagination
              class="float-right"
              v-model="page"
              :length="totalPages"
              total-visible="7"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
              @input="handlePageChange"
            ></v-pagination>
          </div> -->
          <v-data-table
            :headers="columnsProductsInventory"
            :items="productsInventory"
            item-key="_id"
            class="elevation-1"
            hide-default-footer
            disable-pagination
            :loading="loadingProductInventory"
            loading-text="Loading... Please wait"
          >
            <!-- <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col>
                    <v-text-field
                      @keyup="globalSearch"
                      v-model="search"
                      label="Search"
                      class="mx-4 mt-4"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template> -->
          </v-data-table>
          <div class="custom_pagination_design">
            <span>
              Showing <b>{{ startRecord }}</b> to <b>{{ endRecord }}</b> of
              <b>{{ totalRecord }}</b> Records
            </span>
            <v-pagination
              class="float-right"
              v-model="page"
              :length="totalPages"
              total-visible="7"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
              @input="handlePageChange"
            >
            </v-pagination>
          </div>
        </v-container>
      </v-card>
      <v-card v-if="selectReport == 'Van Inventory'">
        <v-card-title>Van Inventory</v-card-title>
        <v-container>
          <v-data-table
            :headers="columnVanInventory"
            :items="vanInventory"
            item-key="_id"
            class="elevation-1"
            hide-default-footer
            disable-pagination
            :loading="loadingVanInventory"
            loading-text="Loading... Please wait"
          >
            <!-- <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col>
                    <v-text-field
                      @keyup="globalSearch"
                      v-model="search"
                      label="Search"
                      class="mx-4 mt-4"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template> -->
          </v-data-table>
          <div class="custom_pagination_design">
            <span>
              Showing <b>{{ startRecord }}</b> to <b>{{ endRecord }}</b> of
              <b>{{ totalRecord }}</b> Records
            </span>
            <v-pagination
              class="float-right"
              v-model="page"
              :length="totalPages"
              total-visible="7"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
              @input="handlePageChange"
            >
            </v-pagination>
          </div>
        </v-container>
        <!-- <v-container>
          <v-data-table
            :headers="columnVanInventory"
            :items="vanInventory"
            item-key="_id"
            @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            show-expand
            class="elevation-1"
            hide-default-footer
            :loading="loadingVanInventory"
            loading-text="Loading... Please wait"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col>
                    <v-text-field
                      @keyup="globalSearch"
                      v-model="search"
                      label="Search"
                      class="mx-4 mt-4"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:expanded-item="{ item }">
              <td colspan="9">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Product Name</th>
                        <th>Quantity Per Pack</th>
                        <th>Type</th>
                        <th>Volume Type</th>
                        <th>Volume Value</th>
                        <th>Actual Price</th>
                        <th>Retail Price</th>
                        <th>Quantity</th>
                        <th>Image</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="variant in item.vanProducts"
                        :key="variant._id"
                      >
                        <td>{{ variant.product_name }}</td>
                        <td>{{ variant.variant_quantity_per_pack }}</td>
                        <td>{{ variant.variant_type }}</td>
                        <td>{{ variant.variant_volume_type }}</td>
                        <td>{{ variant.variant_volume_value }}</td>
                        <td>{{ variant.variant_actual_price }}</td>
                        <td>{{ variant.variant_retail_price }}</td>
                        <td>{{ variant.variant_quantity }}</td>
                        <td>
                          <v-img
                            height="100"
                            width="50"
                            aspect-ratio="1"
                            :src="imageUrl + variant.variant_image"
                            :lazy-src="imageUrl + variant.variant_image"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </template>
          </v-data-table>
          <div class="custom_pagination_design">
            <span>
              Showing <b>{{ startRecord }}</b> to <b>{{ endRecord }}</b> of
              <b>{{ totalRecord }}</b> Records
            </span>
            <v-pagination
              class="float-right"
              v-model="page"
              :length="totalPages"
              total-visible="7"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
              @input="handlePageChange"
            ></v-pagination>
          </div>
        </v-container> -->
      </v-card>
      <v-card v-if="selectReport == 'Damage Product'">
        <v-card-title>Damage Product</v-card-title>
        <v-container>
          <v-data-table
            :headers="columnDamageData"
            :items="damageData"
            item-key="_id"
            class="elevation-1"
            hide-default-footer
            disable-pagination
            :loading="loadingDamageData"
            loading-text="Loading... Please wait"
          >
            <template v-slot:top>
              <!-- <v-toolbar flat>
                <v-row>
                  <v-col>
                    <v-text-field
                      @keyup="globalSearch"
                      v-model="search"
                      label="Search"
                      class="mx-4 mt-4"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar> -->
            </template>
            <template v-slot:[`item.variant_image`]="{ item }">
              <v-img
                height="100"
                width="50"
                aspect-ratio="1"
                :src="imageUrl + item.variant_image"
                :lazy-src="imageUrl + item.variant_image"
              />
            </template>
          </v-data-table>
          <div class="custom_pagination_design">
            <span>
              Showing <b>{{ startRecord }}</b> to <b>{{ endRecord }}</b> of
              <b>{{ totalRecord }}</b> Records
            </span>
            <v-pagination
              class="float-right"
              v-model="page"
              :length="totalPages"
              total-visible="7"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
              @input="handlePageChange"
            >
            </v-pagination>
          </div>
        </v-container>
      </v-card>
      <v-card v-if="selectReport == 'Payout Report'">
        <v-card-title>Payout Report</v-card-title>
        <v-data-table
          :headers="columnPayoutData"
          :items="payoutData"
          item-key="date"
          class="elevation-1"
          :loading="loadingSalesData"
          loading-text="Loading... Please wait"
        >
        </v-data-table>
        <!-- <div class="custom_pagination_design">
          <span>
            Showing <b>{{ startRecord }}</b> to <b>{{ endRecord }}</b> of
            <b>{{ totalRecord }}</b> Records
          </span>
          <v-pagination
            class="float-right"
            v-model="page"
            :length="totalPages"
            total-visible="7"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
            @input="handlePageChange"
          >
          </v-pagination>
        </div> -->
      </v-card>
      <v-card v-if="selectReport == 'Sales Report'">
        <v-card-title>Sales Report</v-card-title>
        <v-data-table
          :headers="columnSalesData"
          :items="salesData"
          item-key="_id"
          class="elevation-1"
          :loading="loadingSalesData"
          loading-text="Loading... Please wait"
        >
        </v-data-table>
        <!-- <div class="custom_pagination_design">
          <span>
            Showing <b>{{ startRecord }}</b> to <b>{{ endRecord }}</b> of
            <b>{{ totalRecord }}</b> Records
          </span>
          <v-pagination
            class="float-right"
            v-model="page"
            :length="totalPages"
            total-visible="7"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
            @input="handlePageChange"
          >
          </v-pagination>
        </div> -->
      </v-card>
      <v-card v-if="selectReport == 'Orders Report'">
        <v-card-title>Orders Report</v-card-title>
        <v-data-table
          :headers="columnOrdersData"
          :items="ordersData"
          item-key="_id"
          class="elevation-1"
          :loading="loadingSalesData"
          loading-text="Loading... Please wait"
        >
        </v-data-table>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import Buttons from "../../component/Buttons.vue";
import moment from "moment";
export default {
  components: { Buttons },
  data() {
    return {
      isAction: false,
      actionMessage: "",
      moduleName: "Reports",
      modulePermission: [],
      reports: [
        "Products Inventory",
        // "Van Inventory",
        "Damage Product",
        "Sales Report",
        "Orders Report",
        "Payout Report"
      ],
      salesReports: ["Daily", "Weekly", "Monthly"],
      ordersReports: ["Daily", "Weekly", "Monthly"],
      payoutReports: "Daily",
      selectReport: "",
      productsInventory: [],
      loadingProductInventory: false,
      singleExpand: true,
      expanded: [],
      stores :[],
      store : null,
      storeId : null,
      storeName : "",
      page: 1,
      totalPages: 0,
      startRecord: 0,
      endRecord: 0,
      totalRecord: 0,
      numbers: [],
      search: "",
      imageUrl: process.env.VUE_APP_Image_Link,
      vanInventory: [],
      loadingVanInventory: false,
      damageData: [],
      loadingDamageData: false,
      loadingExcel: false,
      menu: false,
      damageDateRange: [],
      salesDateRange: [],
      payoutDateRange: [],
      selectSalesReports: "",
      selectPayoutReports: "Daily",
      allCategories: [],
      categoryId: "",
      allSubCategories: [],
      subCategoryId: "",
      allProducts: [],
      productId: "",
      productsVariants: [],
      variantId: "",
      salesData: [],
      payoutData: [],
      loadingSalesData: false,
      vans: [],
      vanId: "",
      orderDateRange: [],
      selectOrdersReports: "",
      ordersData: []
    };
  },
  computed: {
    columnsProductsInventory() {
      return [
        { sortable: false, text: "Product Name", value: "product_name" },
        {
          sortable: false,
          text: "Quantity Per Pack",
          value: "variant_quantity_per_pack"
        },
        { sortable: false, text: "Type", value: "variant_type" },
        { sortable: false, text: "Volume Type", value: "variant_volume_type" },
        {
          sortable: false,
          text: "Volume Value",
          value: "variant_volume_value"
        },
        { sortable: false, text: "Quantity", value: "variant_quantity" }
      ];
    },
    storeNames() {
      return this.stores.map(store => store.name);
    },
    columnVanInventory() {
      return [
        { sortable: false, text: "Van Number", value: "vehicle_number" },
        { sortable: false, text: "Product Name", value: "product_name" },
        {
          sortable: false,
          text: "Quantity Per Pack",
          value: "variant_quantity_per_pack"
        },
        { sortable: false, text: "Type", value: "variant_type" },
        { sortable: false, text: "Volume Type", value: "variant_volume_type" },
        {
          sortable: false,
          text: "Volume Value",
          value: "variant_volume_value"
        },
        {
          sortable: false,
          text: "Actual Price",
          value: "variant_actual_price"
        },
        {
          sortable: false,
          text: "Retail Price",
          value: "variant_retail_price"
        },
        { sortable: false, text: "Quantity", value: "variant_quantity" }
      ];
    },
    columnDamageData() {
      return [
        { sortable: false, text: "Name", value: "name", width: "150px" },
        {
          sortable: false,
          text: "damageDate",
          value: "damageDate",
          width: "180px"
        },
        { sortable: false, text: "UPC", value: "upc_number", width: "100px" },
        { sortable: false, text: "SKU", value: "sku_number", width: "150px" },
        {
          sortable: false,
          text: "Quantity",
          value: "quantity",
          width: "150px"
        },
        {
          sortable: false,
          text: "Damaged By",
          value: "damagedBy",
          width: "180px"
        }
      ];
    },
    columnSalesData() {
      return [
        { sortable: false, text: "Date", value: "date" },
        { sortable: false, text: "Total", value: "total_sales" },
        { sortable: false, text: "Total Driver Tip", value: "total_driver_tip" },
        { sortable: false, text: "Grand Total", value: "total_grand_total" },
        { sortable: false, text: "Total Delivery Charge", value: "total_delivery_charges" },
        { sortable: false, text: "Total Tax", value: "total_tax" },
        // { sortable: false, text: "Total Product Price", value: "total_order_items_price" }
      ];
    },
    columnPayoutData() {
      return [
        { sortable: false, text: "Date", value: "date" },
        { sortable: false, text: "Store Name", value: "store_name" },
        { sortable: false, text: "Total Sales", value: "total_sales" },
        { sortable: false, text: "Platform Usage Fee(%)", value: "platform_usage_fee_percent" },
        { sortable: false, text: "Platform Usage Fees", value: "platform_usage_fee" },
      ];
    },
    columnOrdersData() {
      return [
        { sortable: false, text: "Date", value: "date" },
        { sortable: false, text: "Total Orders", value: "totalOrders" },
        { sortable: false, text: "Cancelled Orders", value: "cancelledOrders" },
        { sortable: false, text: "Completed Orders", value: "completedOrders" }
      ];
    }
  },
  methods: {
    getVanList() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "van/getall")
        .then(response => {
          if (response.status == 200) {
            this.vans = response.data.vans;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    getAllCategories(storeID) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "category/byStore/" + storeID)
        .then(response => {
          if (response.status == 200) {
            this.allCategories = response.data.categories;
            // console.log(response.data);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    
    getStoreList() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "store/getall")
        .then((response) => {
          if (response.status == 200) {
            this.stores = response.data.stores;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSubcategory() {
      this.subCategoryId = null;
      this.productId = null;
      this.variantId = null;
      // if (this.salesDateRange.length == 0 || this.selectSalesReports == null) {
      //   console.log("Please select Category or Reports");
      //   // alert("Please select Category or Reports");
      //   this.categoryId = "";
      // } else {
      //   console.log("else");
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "product/getSubCategory/" +
            this.categoryId
        )
        .then(response => {
          if (response.status == 200) {
            this.allSubCategories = response.data.subCategories;
            this.getSalesData();
          }
        })
        .catch(error => {
          console.log(error);
        });
      // }
    },
    getProduct() {
      this.productId = null;
      this.variantId = null;
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "purchase/getProducts/" +
            this.subCategoryId
        )
        .then(response => {
          if (response.status == 200) {
            this.allProducts = response.data.products;
            this.getSalesData();
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    getSelectedStore(){
      const selectedStoreObject = this.stores.find(store => store.name === this.store);
      let storeID = selectedStoreObject._id;
      this.storeId = selectedStoreObject._id;
      this.storeName = selectedStoreObject.name;
      this.getSalesData();
      this.getOrdersData();
      this.getRelatedData();
      this.getAllCategories(storeID);
    },
    getProductsVariants() {
      this.variantId = null;
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL + "product/edit/" + this.productId
        )
        .then(response => {
          if (response.status == 200) {
            this.productsVariants = response.data.product.product_variants;
            this.getSalesData();
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    getVariantId() {
      this.getSalesData();
    },
    getPayoutData() {
      // console.log(data);
      var days = "";
      if (this.payoutDateRange != null) {
        var d1 = new Date(this.payoutDateRange[0]);
        var d2 = new Date(this.payoutDateRange[1]);
        var minutes = 1000 * 60;
        var hours = minutes * 60;
        var day = hours * 24;
        days = 1 + Math.round((d2 - d1) / day);
      } else {
        days = 0;
      }
      if (days > 180) {
        alert("can not select more than 180 days");
        this.payoutDateRange = null;
      } else {
        let data = {
          dateRange: this.payoutDateRange,
          dataType: this.selectPayoutReports,
          storeId : this.storeId,
        };
      axios
        .post(process.env.VUE_APP_API_BASE_URL + "reports/payoutData", data)
        .then(response => {
          if (response.status == 200) {
            this.payoutData = response.data.payouts;
            // this.loadingSalesData = false;
            // this.totalPages = response.data.sales.last_page;
            // this.startRecord = response.data.sales.from;
            // this.endRecord = response.data.sales.to;
            // this.totalRecord = response.data.sales.total;
            // this.numbers = [];
            // for (
            //   let num = response.data.sales.from;
            //   num <= response.data.sales.to;
            //   num++
            // ) {
            //   this.numbers.push(num);
            // }
          }
        })
        .catch(error => {
          console.log(error);
        });
      }
    },

    getSalesData() {
      var days = "";
      if (this.salesDateRange != null) {
        var d1 = new Date(this.salesDateRange[0]);
        var d2 = new Date(this.salesDateRange[1]);
        var minutes = 1000 * 60;
        var hours = minutes * 60;
        var day = hours * 24;
        days = 1 + Math.round((d2 - d1) / day);
      } else {
        days = 0;
      }
      if (days > 180) {
        alert("can not select more than 180 days");
        this.salesDateRange = null;
      } else {
        let data = {
          dateRange: this.salesDateRange,
          dataType: this.selectSalesReports,
          categoryId: this.categoryId,
          storeId : this.storeId,
          subCategoryId: this.subCategoryId,
          productId: this.productId,
          variantId: this.variantId
        };
        // console.log(data);
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "reports/salesData", data)
          .then(response => {
            if (response.status == 200) {
              this.salesData = response.data.sales;
              console.log("this.salesData : ", this.salesData);
              // this.loadingSalesData = false;
              // this.totalPages = response.data.sales.last_page;
              // this.startRecord = response.data.sales.from;
              // this.endRecord = response.data.sales.to;
              // this.totalRecord = response.data.sales.total;
              // this.numbers = [];
              // for (
              //   let num = response.data.sales.from;
              //   num <= response.data.sales.to;
              //   num++
              // ) {
              //   this.numbers.push(num);
              // }
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    getVanDetails() {
      this.getRelatedData();
    },
    getRelatedData() {
      this.loadingExcel = false;

      if (this.selectReport == "Products Inventory") {
        if (this.search == "") {
          this.search = null;
        }
        this.loadingProductInventory = true;
        axios
          .get(
            process.env.VUE_APP_API_BASE_URL +
              "reports/productInventory/ByStore/" +
              this.storeId +
              "?page=" +
              this.page
          )
          .then(response => {
            if (response.status == 200) {
              this.productsInventory = response.data.productsInventory.data;
              this.loadingProductInventory = false;
              this.totalPages = response.data.productsInventory.last_page;
              this.startRecord = response.data.productsInventory.from;
              this.endRecord = response.data.productsInventory.to;
              this.totalRecord = response.data.productsInventory.total;
              this.numbers = [];
              for (
                let num = response.data.productsInventory.from;
                num <= response.data.productsInventory.to;
                num++
              ) {
                this.numbers.push(num);
              }
            }
          })
          .catch(error => {
            console.log(error);
          });
      } else if (this.selectReport == "Van Inventory") {
        if (this.vanId == "") {
          this.vanId = null;
        }
        if (this.search == "") {
          this.search = null;
        }
        this.loadingProductInventory = true;
        axios
          .get(
            process.env.VUE_APP_API_BASE_URL +
              "reports/vanInventory/" +
              this.vanId +
              "/" +
              this.search +
              "?page=" +
              this.page
          )
          .then(response => {
            if (response.status == 200) {
              this.vanInventory = response.data.vanInventory.data;
              this.loadingProductInventory = false;
              this.totalPages = response.data.vanInventory.last_page;
              this.startRecord = response.data.vanInventory.from;
              this.endRecord = response.data.vanInventory.to;
              this.totalRecord = response.data.vanInventory.total;
              this.numbers = [];
              for (
                let num = response.data.vanInventory.from;
                num <= response.data.vanInventory.to;
                num++
              ) {
                this.numbers.push(num);
              }
            }
          })
          .catch(error => {
            console.log(error);
          });
      } else if (this.selectReport == "Damage Product") {
        if (this.search == "") {
          this.search = null;
        }
        if (this.damageDateRange == "") {
          this.damageDateRange = null;
        }
        this.loadingDamageData = true;
        axios
          .get(
            process.env.VUE_APP_API_BASE_URL +
              "reports/damageVariant/ByStore/" +
              this.storeId +
              "/" +
              this.damageDateRange +
              "?page=" +
              this.page
          )
          .then(response => {
            if (response.status == 200) {
              this.damageData = response.data.damageData.data;
              this.loadingDamageData = false;
              this.totalPages = response.data.damageData.last_page;
              this.startRecord = response.data.damageData.from;
              this.endRecord = response.data.damageData.to;
              this.totalRecord = response.data.damageData.total;
              this.numbers = [];
              for (
                let num = response.data.damageData.from;
                num <= response.data.damageData.to;
                num++
              ) {
                this.numbers.push(num);
              }
            }
          })
          .catch(error => {
            console.log(error);
          });
      }else if(this.selectReport == "Payout Report") {
        if (this.search == "") {
          this.search = null;
        }
        this.loadingSalesData = true;

        let requestdata = {
          dateRange: this.payoutDateRange,
          dataType: this.selectPayoutReports,
          storeId: this.storeId,
        };
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "reports/payoutData", requestdata)
          .then(response => {
            if (response.status == 200) {
              this.payoutData = response.data.payouts;
              this.loadingSalesData = false;
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    getOrdersData() {
      let data = {
        dateRange: this.orderDateRange,
        storeId : this.storeId,
        dataType: this.selectOrdersReports
      };
      console.log(data);
      axios
        .post(process.env.VUE_APP_API_BASE_URL + "reports/ordersData", data)
        .then(response => {
          if (response.status == 200) {
            this.ordersData = response.data.orders;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    clearFilterOrders() {
      this.orderDateRange = [];
      this.selectOrdersReports = null;
      this.getOrdersData();
    },
    getDate() {
      this.getRelatedData();
    },
    format_date(value) {
      if (value) {
        var res = new Date(value * 1000);
        return moment(res).format("DD/MM/YYYY h:m:s");
      }
    },
    clearFilter() {
      this.salesDateRange = [];
      this.selectSalesReports = null;
      this.categoryId = null;
      this.subCategoryId = null;
      this.productId = null;
      this.variantId = null;
      this.getSalesData();
    },
    downloadExcel() {
      this.loadingExcel = true;
      let url = null;
      // if (this.selectReport == "Sales Report") {
      //   url = process.env.VUE_APP_API_BASE_URL + "download/excel/salesData";
      //   axios
      //     .post(url)
      //     .then((response) => {
      //       if (response.status == 200) {
      //         window.open(url);
      //         this.loadingExcel = false;
      //       }
      //     })
      //     .catch((error) => {
      //       this.loadingExcel = false;
      //       console.log(error);
      //     });
      // } else {
      let dateRange = null;
      let dateRangeSelected = false;
      if (this.selectReport == "Products Inventory") {
        dateRangeSelected = true;
      }
      if (this.selectReport == "Van Inventory") {
        dateRangeSelected = true;
      }
      if (this.selectReport == "Damage Product") {
        if (this.damageDateRange != null) {
          if (this.damageDateRange.length == 0) {
            this.damageDateRange = null;
          }
          dateRangeSelected = true;
        } else {
          this.actionMessage = "Select Date Range";
        }
        dateRange = this.damageDateRange;
      
      } else {
          this.actionMessage = "Select Date Range & Type of Sales Report";
        }
      if (this.selectReport == "Sales Report") {
        dateRange = this.salesDateRange;
        if (
          dateRange != null &&
          dateRange.length > 0 &&
          this.selectSalesReports !== ""
        ) {
          dateRangeSelected = true;
        } else {
          this.actionMessage = "Select Date Range & Type of Sales Report";
        }
      }
      if (this.selectReport == "Payout Report") {
        dateRange = this.payoutDateRange;
        if (
          dateRange != null &&
          dateRange.length > 0 &&
          this.selectPayoutReports !== ""
        ) {
          dateRangeSelected = true;
        } else {
          this.actionMessage = "Select Date Range & Type of Order Report";
        }
      }
      if (this.selectReport == "Orders Report") {
        dateRange = this.orderDateRange;
        if (
          dateRange != null &&
          dateRange.length > 0 &&
          this.selectOrdersReports !== ""
        ) {
          dateRangeSelected = true;
        } else {
          this.actionMessage = "Select Date Range & Type of Order Report";
        }
      }

      if (dateRangeSelected == false) {
        this.isAction = true;
        this.loadingExcel = false;
      } else {
        url =
          process.env.VUE_APP_API_BASE_URL +
          "download/excel/ByStore/" +
          this.storeId +
          "/" +
          this.selectReport +
          "/" +
          dateRange +
          "/" +
          ((this.selectSalesReports || this.selectOrdersReports || this.selectPayoutReports) ? (this.selectSalesReports || this.selectOrdersReports || this.selectPayoutReports) : null);
        axios
          .get(url, {
            method: "GET",
            responseType: "blob" // important
          })
          .then(response => {
            if (response.status == 200) {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              const fileName = this.storeName + '-' + this.selectReport;
              link.setAttribute("download", `${fileName}.xlsx`);
              document.body.appendChild(link);
              link.click();
              this.loadingExcel = false;
            }
          })
          .catch(error => {
            this.loadingExcel = false;
            console.log(error);
          });
      }
    },
    handlePageChange(value) {
      this.loadingProductInventory = true;
      this.page = value;
      this.getRelatedData();
    },
    globalSearch() {
      this.loadingProductInventory = true;
      this.getRelatedData();
    }
  },
  mounted() {
    let permissions = JSON.parse(sessionStorage.getItem("user-permission"));
    this.modulePermission = permissions[this.moduleName];
    this.getStoreList();
    this.getVanList();
  }
};
</script>
<style scoped></style>
